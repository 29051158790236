import store from '../../../store/index';
import { Actions } from './permissionContants';

// const authPermissionGroup = store.getters['permissions/authPermissionGroup'];
// store.modules.permissions.getters.authPermissionGroup;

const emptyUserType = {
  id: 0,
  tipo_usuario: '',
  estatus_sistema: true,
};

const can = ({ actions, resource }) => {
  try {
    const authPermissionGroup =
      store.getters['permissions/authPermissionGroup'];
    const fullActions = actions.map(
      (action) => `${action}_${resource.toLowerCase()}`
    );
    const resources = [];
    authPermissionGroup.forEach((group) => {
      const findedPermission = group.permissions.filter((permission) =>
        fullActions.some((action) => permission.codename.includes(action))
      );
      if (findedPermission) {
        resources.push(...findedPermission);
      }
    });
    return resources.length !== 0;
  } catch (error) {
    return false;
  }
};

const canView = (resource) => {
  return can({ actions: [Actions.view], resource: resource });
};

const canAdd = (resource) => {
  return can({ actions: [Actions.add], resource: resource });
};

const canChange = (resource) => {
  return can({ actions: [Actions.change], resource: resource });
};

const canDelete = (resource) => {
  return can({ actions: [Actions.delete], resource: resource });
};

const insufficientPermissionsMessage = () => {
  return 'Lo sentimos, no tienes los permisos suficientes para realizar esta acción';
};

const testStore = () => {
  const authPermissionGroup = store.getters['permissions/authPermissionGroup'];
  console.log('authPermissionGroup', authPermissionGroup);
};

const checkUserTypeMatch = (
  currentUserType = emptyUserType,
  userTypeText = 'No especificado'
) => {
  return currentUserType.tipo_usuario
    .toLowerCase()
    .includes(userTypeText.toLowerCase());
};

/** Metodos para almacenar en localStorage */

const setLocalCurrentPermissionGroup = (permissions) => {
  localStorage.setItem('kpCurrentPermissionGroup', JSON.stringify(permissions));
};

const getLocalCurrentPermissionGroup = () => {
  const localStorageCurrentPermissionGroup = localStorage.getItem(
    'kpCurrentPermissionGroup'
  );
  if (localStorageCurrentPermissionGroup) {
    return JSON.parse(localStorageCurrentPermissionGroup);
  } else {
    return [];
  }
};

const removeLocalCurrentPermissionGroup = () => {
  localStorage.removeItem('kpCurrentPermissionGroup');
};

export {
  can,
  canAdd,
  canChange,
  canDelete,
  canView,
  checkUserTypeMatch,
  getLocalCurrentPermissionGroup,
  insufficientPermissionsMessage,
  removeLocalCurrentPermissionGroup,
  setLocalCurrentPermissionGroup,
  testStore,
};
