import { Actions } from '../modules/shared/helpers/permissionContants';

const schoolControlReportChildren = [
  {
    path: 'alumnos',
    name: 'Control Escolar Reportes Alumnos',
    component: () =>
      import(
        /* webpackChunkName: "school_control_student_report" */ '../modules/employees/components/KnSchoolControlStudentReport/KnSchoolControlStudentReport.vue'
      ),
    meta: {
      actions: [Actions.view],
      resources: ['alumno'],
    },
  },
  {
    path: 'altas-bajas',
    name: 'Control Escolar Reportes Movimientos',
    component: () =>
      import(
        /* webpackChunkName: "school_control_movements_report" */ '../modules/employees/components/KnSchoolControlStudentReport/KnSchoolControlStudentReport.vue'
      ),
    meta: {
      actions: [Actions.view],
      resources: ['alumno'],
    },
  },
  {
    path: 'control-rendimiento',
    name: 'Control Escolar Reportes Rendimiento',
    component: () =>
      import(
        /* webpackChunkName: "school_control_grades_report" */ '../modules/employees/components/KnSchoolControlStudentReport/KnSchoolControlStudentReport.vue'
      ),
    meta: {
      actions: [Actions.view],
      resources: ['alumno'],
    },
  },
];
export default schoolControlReportChildren;
