import { mapMutations } from 'vuex';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from './api/config';

export default {
  components: {},
  name: 'App',

  data: () => ({
    //
  }),
  computed: {},
  mounted() {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: false,
      track_pageview: true,
      persistence: 'localStorage',
      ignore_dnt: true,
    });

    // console.log('mixpanel?', mixpanel);
    this.setMixpanel(mixpanel);
  },
  methods: {
    ...mapMutations(['setMixpanel']),
  },
};
