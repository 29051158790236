import { api, commonAPI } from '@/api/axios-base';
/**
 * Se mandan datos al servidor
 * @param {string} url ruta ha ejecutar
 * @param {Object} data datos a mandar al back
 */
export const postItem = async (url, data, option = null) => {
  try {
    const response = await api.post(url, data, option);
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error(
      'Ocurrió un error al intenter realizar la petición POST',
      error
    );
    return {
      e: 'Ocurrió un error al intenter realizar la petición POST. ' + error,
    };
  }
};
/**
 * Se mandan datos al servidor
 * @param {string} url ruta ha ejecutar
 * @param {Object} data datos a mandar al back
 * @param {option} option datos que se mandan al usar multipart
 */
export const postOptionItem = async (url, data, option = null) => {
  try {
    const response = await api.post(url, data, option);
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error(
      'Ocurrió un error al intenter realizar la petición POST',
      error
    );
    return { e: error };
  }
};
/**
 *@param {string} url ruta ha ejecutar
 *@param {Object} data datos a mandar al back
 */
export const commonPost = async (url, data) => {
  try {
    const response = await commonAPI.post(url, data);
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.error(
      'Ocurrió un error al intenter realizar la petición CommonPost',
      error
    );
    return { e: error };
  }
};
/**
 * Se obtienen todos los datos de la ruta.
 * @param {string} url ruta ha ejecutar
 */
export const getItem = async (url) => {
  try {
    const response = await api.get(url);
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.log('Ocurrió un error al intenter realizar la petición GET', error);
    return {
      e: 'Ocurrió un error al intenter realizar la petición GET. ' + error,
    };
  }
};
/**
 * Se eliminan los datos por Id
 * @param {string} url ruta a ejecutar
 * @param {number} id identificador del elemento a elminar
 */
export const deleteItem = async (url, id) => {
  try {
    const response = await api.delete(`${url}/${id}`);
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.log(
      'Ocurrió un error al intenter realizar la petición DELETE',
      error
    );
    return {
      e: 'Ocurrió un error al intenter realizar la petición DELETE. ' + error,
    };
  }
};
/**
 * Se eliminan los dato
 * @param {string} url ruta a ejecutar
 * @param {object} option configuración a pasarle a la instancia de axios
 */
export const deleteItemWithOptions = async (url, option = null) => {
  try {
    const response = await api.delete(`${url}`, option);
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.log(
      'Ocurrió un error al intenter realizar la petición DELETE',
      error
    );
    return {
      e: 'Ocurrió un error al intenter realizar la petición DELETE. ' + error,
    };
  }
};
/**
 * Actualiza datos por Id
 * @param {string} url ruta a ejecutar
 * @param {Object} data datos que se mandan al back
 */
export const putItem = async (url, data, option = null) => {
  try {
    const response = await api.put(url, data, option);
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    console.log('Ocurrió un error al intenter realizar la petición PUT', error);
    return {
      e: 'Ocurrió un error al intenter realizar la petición PUT' + error,
    };
  }
};
