import Vue from 'vue';
import VueRouter from 'vue-router';
import defaultRoutes from './defaultRoutes';
import appRoutes from './appRoutes';

Vue.use(VueRouter);

/// AUTOR: Tania Marin
/// RESEÑANTE: Raúl Altamirano Lozano
/// FECHA: 29/07/2022
/// COMENTARIOS:
/// - Limitar el tipo de usuario que debe ingresar al sistema
/// - Determinar las páginas que deben estar habilitadas para cada usuario dependiendo de su nivel de accesos.

const routes = [...defaultRoutes, ...appRoutes];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
