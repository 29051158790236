import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#0047BA', // Azul
        secondary: '#F6DFA4', // Beige
        accent: '#FF7276', // Rosa
        error: '#FF5F00', // Naranja
        info: '#136FEE', // Azul claro
        success: '#00AE42', // Verde
        warning: '#FFB600', // Amarillo huevo
        gray1: '#EEEEEE',
        gray2: '#DDDDDD',
        gray3: '#F5F5F5', // Gris para fondo de POS
        graymedio: '#BBBBBB',
        grayoscuro: '#7A8893',
        graydefault: '#666666',
        purple: '#A45C81',
      },
    },
  },
});
