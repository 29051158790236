const defaultRoutes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/',
    component: () =>
      import('../modules/shared/layouts/EmptyLayout/EmptyLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () =>
          import(
            /* webpackChunkName: "login" */ '../modules/shared/views/Login/Login.vue'
          ),
      },
    ],
  },
  {
    path: '*',
    component: () =>
      import(
        /* webpackChunkName: "not_found" */ '../modules/shared/views/KnNotFound/KnNotFount.vue'
      ),
  },
];

export default defaultRoutes;
