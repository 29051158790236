import axios from 'axios';
import store from '../store';
import { ENVIRONMENT } from './config';

//const APIUrl = 'https://kn.e32.tech'
// const APIUrl = 'https://keystone.kinderplus.mx';
const APIUrl =
  ENVIRONMENT === 'prod'
    ? 'https://keystone.kinderplus.mx' // backend de keystone
    : 'http://127.0.0.1:7000';

const api = axios.create({
  baseURL: APIUrl,
  headers: { contenttype: 'application/json' },
});

const commonAPI = axios.create({
  baseURL: APIUrl,
  headers: { contenttype: 'application/json' },
});

api.interceptors.request.use(
  (config) => {
    config.headers = { Authorization: `JWT ${store.state.apiTokens.access}` };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      await store.dispatch('refreshToken');
      originalRequest.headers[
        'Authorization'
      ] = `JWT ${store.state.apiTokens.access}`;
      return api.request(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { api, APIUrl, commonAPI };
