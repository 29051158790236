import { Actions } from '../modules/shared/helpers/permissionContants';
import { isAuthenticated, checkProfilePermissions } from './appGuards';
import schoolControlReportChildren from './schoolControlReportChildren';
const appRoutes = [
  {
    path: '/app',
    beforeEnter: isAuthenticated,
    component: () =>
      import('../modules/shared/layouts/AppLayout/AppLayout.vue'),
    children: [
      {
        path: '/',
        redirect: 'dashboard',
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "school_control_dashboard" */ '../modules/dashboard/views/Index.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['ingreso', 'egreso', 'adeudo', 'factura'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'dashboard/tutores',
        name: 'Dashboard Tutores',
        component: () =>
          import(
            /* webpackChunkName: "tutor_dashboard" */ '../modules/dashboard/views/tutorDashboard/TutorDashboardIndex.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['alumno', 'boleta', 'factura'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'dashboard/docentes',
        name: 'Dashboard Docentes',
        component: () =>
          import(
            /* webpackChunkName: "teacher_dashboard" */ '../modules/dashboard/views/teacherDashboard/TeacherDashboardIndex.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['alumno', 'boleta'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'grupos',
        name: 'Grupos',
        component: () =>
          import(
            /* webpackChunkName: "groups" */ '../modules/employees/views/groups/GroupIndex.vue'
          ),
        meta: {
          requireAuth: true,
          teacherAccess: true,
          actions: [Actions.view],
          resources: ['alumno', 'boleta', 'grupo'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'anuncios',
        name: 'Anuncios',
        component: () =>
          import(
            /* webpackChunkName: "announcements" */ '../modules/employees/views/announcements/AnnouncementIndex.vue'
          ),
        meta: {
          teacherAccess: true,
          actions: [Actions.view],
          resources: ['anuncio'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'anuncios/nuevo',
        name: 'Nuevo Anuncio',
        component: () =>
          import(
            /* webpackChunkName: "new_announcements" */ '../modules/employees/components/KnFormAnnouncement/KnFormAnnouncement.vue'
          ),
        meta: {
          teacherAccess: true,
          actions: [Actions.add],
          resources: ['anuncio'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'anuncios/editar',
        name: 'Editar Anuncio',
        component: () =>
          import(
            /* webpackChunkName: "edit_announcement" */ '../modules/employees/components/KnFormAnnouncement/KnFormAnnouncement.vue'
          ),
        props: true,
        meta: {
          teacherAccess: true,
          actions: [Actions.view, Actions.change],
          resources: ['anuncio'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'boletas',
        name: 'Boletas',
        component: () =>
          import(
            /* webpackChunkName: "reportCards" */ '../modules/employees/views/reportCards/ReportCardIndex.vue'
          ),
        meta: {
          teacherAccess: true,
          actions: [Actions.view],
          resources: ['boleta'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'boletas/nuevo',
        name: 'Nueva Boleta',
        component: () =>
          import(
            /* webpackChunkName: "new_reportCard" */ '../modules/employees/components/KnFormReportCard/KnFormReportCard.vue'
          ),
        meta: {
          teacherAccess: true,
          actions: [Actions.add],
          resources: ['boleta'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'boletas/editar',
        name: 'Editar Boleta',
        component: () =>
          import(
            /* webpackChunkName: "edit_reportCard" */ '../modules/employees/components/KnFormReportCard/KnFormReportCard.vue'
          ),
        props: true,
        meta: {
          teacherAccess: true,
          actions: [Actions.view, Actions.change],
          resources: ['boleta'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'alumnos',
        name: 'Alumnos',
        component: () =>
          import(
            /* webpackChunkName: "students" */ '../modules/students/views/Index.vue'
          ),
        meta: {
          tutorAccess: true,
          teacherAccess: true,
          actions: [Actions.view],
          resources: ['alumno'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'alumnos/nuevo',
        name: 'Nuevo Alumno',
        component: () =>
          import(
            /* webpackChunkName: "new_students" */ '../modules/students/components/KnFormStudent/KnFormStudent.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['alumno'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'alumnos/editar/:id',
        name: 'Editar Alumno',
        component: () =>
          import(
            /* webpackChunkName: "edit_students" */ '../modules/students/components/KnFormStudent/KnFormStudent.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['alumno'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'estimulacion-temprana',
        name: 'Estimulacion Temprana',
        component: () =>
          import(
            /* webpackChunkName: "early_students" */ '../modules/students/views/earlyStimulationIndex/EarlyStimulationIndex.vue'
          ),
        meta: {
          tutorAccess: true,
          teacherAccess: true,
          actions: [Actions.view],
          resources: ['alumno'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'estimulacion-temprana/nuevo',
        name: 'Nuevo Estimulacion Temprana',
        component: () =>
          import(
            /* webpackChunkName: "new_students" */ '../modules/students/components/KnFormEarlyStimulation/KnFormEarlyStimulation.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['alumno'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'estimulacion-temprana/editar',
        name: 'Editar Estimulacion Temprana',
        component: () =>
          import(
            /* webpackChunkName: "edit_students" */ '../modules/students/components/KnFormEarlyStimulation/KnFormEarlyStimulation.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['alumno'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'alumnos/estado-cuenta/:id',
        name: 'Estado de Cuenta Alumno',
        component: () =>
          import(
            /* webpackChunkName: "account_status_students" */ '../modules/students/components/KnStudentAccountStatus/KnStudentAccountStatus.vue'
          ),
        props: true,
        meta: {
          tutorAccess: true,
          teacherAccess: true,
          actions: [Actions.view],
          resources: ['adeudo', 'ingreso', 'factura'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'empleados',
        name: 'Empleados',
        component: () =>
          import(
            /* webpackChunkName: "employees" */ '../modules/employees/views/Index.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['empleado'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'empleados/nuevo',
        name: 'Nuevo Empleado',
        component: () =>
          import(
            /* webpackChunkName: "new_employees" */ '../modules/employees/components/KnFormEmployee/KnFormEmployee.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['empleado'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'empleados/editar',
        name: 'Editar Empleado',
        component: () =>
          import(
            /* webpackChunkName: "edit_employees" */ '../modules/employees/components/KnFormEmployee/KnFormEmployee.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.change],
          resources: ['empleado'],
        },
      },
      {
        path: 'empleados/estado-cuenta',
        name: 'Estado de Cuenta Empleado',
        component: () =>
          import(
            /* webpackChunkName: "account_status_employee" */ '../modules/employees/components/KnEmployeeAccountStatus/KnEmployeeAccountStatus.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view],
          resources: ['empleado', 'egreso'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'profesores',
        name: 'Profesores',
        component: () =>
          import(
            /* webpackChunkName: "teachers" */ '../modules/employees/views/teachers/Index.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['empleado', 'materia', 'grupo'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'materias-profesor/nuevo',
        name: 'Nuevo Materias Profesor',
        component: () =>
          import(
            /* webpackChunkName: "new_subject_teacher" */ '../modules/employees/components/KnFormAssignSubjectTeacher/KnFormAssignSubjectTeacher.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['empleado', 'materia', 'grupo'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'materias-profesor/editar',
        name: 'Editar Materias Profesor',
        component: () =>
          import(
            /* webpackChunkName: "edit_subject_teacher" */ '../modules/employees/components/KnFormAssignSubjectTeacher/KnFormAssignSubjectTeacher.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['empleado', 'materia', 'grupo'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'clubs',
        name: 'Clubes',
        component: () =>
          import(
            /* webpackChunkName: "clubs" */ '../modules/employees/views/clubs/ClubsIndex.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['club'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'clubes/nuevo',
        name: 'Nuevo Club',
        component: () =>
          import(
            /* webpackChunkName: "new_clubs" */ '../modules/employees/components/KnFormClub/KnFormClub.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['club'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'clubes/editar',
        name: 'Editar Club',
        component: () =>
          import(
            /* webpackChunkName: "edit_clubs" */ '../modules/employees/components/KnFormClub/KnFormClub.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.change],
          resources: ['club'],
        },
      },
      {
        path: 'calificaciones',
        name: 'Calificaciones',
        component: () =>
          import(
            /* webpackChunkName: "grades" */ '../modules/employees/views/grades/GradesIndex.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['calificacion'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'calificaciones/nuevo',
        name: 'Nuevo Calificaciones',
        component: () =>
          import(
            /* webpackChunkName: "new_grades" */ '../modules/employees/components/KnFormGrade/KnFormGrade.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['calificacion'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'calificaciones/editar',
        name: 'Editar Calificaciones',
        component: () =>
          import(
            /* webpackChunkName: "edit_grades" */ '../modules/employees/components/KnFormGrade/KnFormGrade.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.change],
          resources: ['calificacion'],
        },
      },
      {
        path: 'calificaciones/lista-calificar/:id',
        name: 'Calificar alumnos',
        component: () =>
          import(
            /* webpackChunkName: "edit_grades" */ '../modules/employees/views/grades/GradesStudentList.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['calificacion'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'autorizar-calificaciones',
        name: 'Autorizar Calificaciones',
        component: () =>
          import(
            /* webpackChunkName: "grade_authorization" */ '../modules/employees/views/gradeAuthorization/GradeAuthorizationIndex.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['calificacion'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'autorizar-calificaciones/por-materia',
        name: 'Autorizar Calificaciones Materias',
        component: () =>
          import(
            /* webpackChunkName: "grade_authorization_subject" */ '../modules/employees/components/KnAuthorizationGradesPerSubject/KnAuthorizationGradesPerSubject.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view],
          resources: ['calificacion'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'autorizar-calificaciones/por-alumno',
        name: 'Autorizar Calificaciones Alumnos',
        component: () =>
          import(
            /* webpackChunkName: "grade_authorization_student" */ '../modules/employees/components/KnAuthorizationGradesPerStudent/KnAuthorizationGradesPerStudent.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view],
          resources: ['calificacion'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'reportes-control-escolar',
        name: 'Control Escolar Reportes',
        component: () =>
          import(
            /* webpackChunkName: "school_control_report" */ '../modules/employees/views/schoolControlReports/SchoolControlReportIndex.vue'
          ),
        redirect: { name: 'Control Escolar Reportes Alumnos' },
        children: [...schoolControlReportChildren],
        meta: {
          actions: [Actions.view],
          resources: ['alumno', 'calificacion', 'materia'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'acciones-grupos',
        name: 'Acciones Grupos',
        component: () =>
          import(
            /* webpackChunkName: "group_actions" */ '../modules/employees/views/groupActions/GroupActions.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['cambiogrupo'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'acciones-grupos/nuevo',
        name: 'Nuevo Acciones Grupos',
        component: () =>
          import(
            /* webpackChunkName: "new_group_actions" */ '../modules/employees/components/KnFormGroupActions/KnFormGroupActions.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['cambiogrupo'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'acciones-grupos/editar',
        name: 'Editar Acciones Grupos',
        component: () =>
          import(
            /* webpackChunkName: "edit_group_actions" */ '../modules/employees/components/KnFormGroupActions/KnFormGroupActions.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.change],
          resources: ['cambiogrupo'],
        },
      },
      {
        path: 'acciones-grupos/mover-alumnos/:id',
        name: 'Mover Alumnos',
        component: () =>
          import(
            /* webpackChunkName: "edit_group_move_students" */ '../modules/employees/components/KnMoveGroupAToGroupB/KnMoveGroupAToGroupB.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['cambiogrupo'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'acciones-grupos/administrar-alumnos/:id',
        name: 'Administrar Alumnos',
        component: () =>
          import(
            /* webpackChunkName: "edit_group_edit_students" */ '../modules/employees/components/KnGroupAddStudent/KnGroupAddStudent.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['cambiogrupo'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'materias',
        name: 'Materias',
        component: () =>
          import(
            /* webpackChunkName: "subjects" */ '../modules/employees/views/subjects/SubjectsIndex.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['materia'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'materias/nuevo',
        name: 'Nuevo Materia',
        component: () =>
          import(
            /* webpackChunkName: "new_subjects" */ '../modules/employees/components/KnFormSubject/KnFormSubject.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['materia'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'materias/editar/:id',
        name: 'Editar Materia',
        component: () =>
          import(
            /* webpackChunkName: "edit_subjects" */ '../modules/employees/components/KnFormSubject/KnFormSubject.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.change],
          resources: ['materia'],
        },
      },
      {
        path: 'planes-estudios',
        name: 'Planes de estudio',
        component: () =>
          import(
            /* webpackChunkName: "syllabus" */ '../modules/employees/views/syllabus/SyllabusIndex.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['planestudios'],
        },

        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'planes-de-estudios/nuevo',
        name: 'Nuevo Plan',
        component: () =>
          import(
            /* webpackChunkName: "new_syllabus" */ '../modules/employees/components/KnFormSyllabus/KnFormSyllabus.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['planestudios'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'planes-de-estudios/editar',
        name: 'Editar Plan',
        component: () =>
          import(
            /* webpackChunkName: "edit_syllabus" */ '../modules/employees/components/KnFormSyllabus/KnFormSyllabus.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.change],
          resources: ['planestudios'],
        },
      },
      {
        path: 'ingresos',
        name: 'Ingresos',
        component: () =>
          import(
            /* webpackChunkName: "income" */ '../modules/income/views/Index.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['ingreso'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'ingresos/nuevo',
        name: 'Agregar Ingresos',
        component: () =>
          import(
            /* webpackChunkName: "new_income" */ '../modules/income/components/KnFormIncome/KnFormIncome.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['ingreso'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'ingresos/editar',
        name: 'Editar Ingresos',
        component: () =>
          import(
            /* webpackChunkName: "edit_income" */ '../modules/income/components/KnFormIncome/KnFormIncome.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['ingreso'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'egresos',
        name: 'Egresos',
        component: () =>
          import(
            /* webpackChunkName: "expenses" */ '../modules/expenses/views/Index.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['egreso'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'egresos/nuevo',
        name: 'Agregar Egresos',
        component: () =>
          import(
            /* webpackChunkName: "new_expenses" */ '../modules/expenses/components/KnFormExpenses.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['egreso'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'egresos/editar',
        name: 'Editar Egresos',
        component: () =>
          import(
            /* webpackChunkName: "edit_expenses" */ '../modules/expenses/components/KnFormExpenses.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['egreso'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'configuracion',
        name: 'Configuracion',
        component: () =>
          import(
            /* webpackChunkName: "configuration" */ '../modules/configuration/views/Index.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: [
            'mvcategoriaingreso',
            'mvcategoriaegreso',
            'mvtipoempleado',
            'mvmotivomovimientoinventario',
            'mvcicloescolar',
            'colegiatura',
            'mvcategoriaproveedor',
            'mvcategoriaproducto',
            'mvestatusordencompra',
            'tema',
          ],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'proveedores',
        name: 'Proveedores',
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ '../modules/suppliers/views/Index.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['proveedor'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'proveedores/nuevo',
        name: 'Nuevo Proveedor',
        component: () =>
          import(
            /* webpackChunkName: "new_suppliers" */ '../modules/suppliers/components/KnFormSupplier/KnFormSupplier.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['proveedor'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'proveedores/editar',
        name: 'Editar Proveedor',
        component: () =>
          import(
            /* webpackChunkName: "edit_suppliers" */ '../modules/suppliers/components/KnFormSupplier/KnFormSupplier.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.view, Actions.change],
          resources: ['proveedor'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'productos',
        name: 'Productos',
        component: () =>
          import(
            /* webpackChunkName: "products" */ '../modules/products/views/Index.vue'
          ),
        meta: {
          actions: [Actions.view],
          resources: ['product'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'productos/nuevo',
        name: 'Nuevo Producto',
        component: () =>
          import(
            /* webpackChunkName: "new_products" */ '../modules/products/components/KnFormProduct/KnFormProduct.vue'
          ),
        meta: {
          actions: [Actions.add],
          resources: ['product'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'productos/editar',
        name: 'Editar Producto',
        component: () =>
          import(
            /* webpackChunkName: "edit_products" */ '../modules/products/components/KnFormProduct/KnFormProduct.vue'
          ),
        props: true,
        meta: {
          actions: [Actions.change],
          resources: ['product'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'punto-de-venta',
        name: 'POS',
        component: () =>
          import(/* webpackChunkName: "pos" */ '../modules/pos/Index.vue'),
        redirect: { name: 'Venta' },
        children: [
          {
            path: 'venta',
            name: 'Venta',
            component: () =>
              import(
                /* webpackChunkName: "pos_sale" */ '../modules/pos/sale/KnSale/KnSale.vue'
              ),
            meta: {
              actions: [Actions.view],
              resources: ['inventario'],
            },
          },
          {
            path: 'ordenes',
            name: 'Ordenes',
            component: () =>
              import(
                /* webpackChunkName: "pos_orders" */ '../modules/pos/orders/KnOrders/KnOrders.vue'
              ),
            meta: {
              actions: [Actions.view],
              resources: ['orden', 'ordendecompra'],
            },
            beforeEnter: checkProfilePermissions,
          },
          {
            path: 'ordenes/nuevo',
            name: 'Nuevo Ordenes',
            component: () =>
              import(
                /* webpackChunkName: "pos_new_orders" */ '../modules/pos/orders/KnFormOrder/KnFormOrder.vue'
              ),
            props: true,
            meta: {
              actions: [Actions.add],
              resources: ['orden', 'ordendecompra'],
            },

            beforeEnter: checkProfilePermissions,
          },
          {
            path: 'ordenes/detalle-orden/:id',
            name: 'Detalle Orden',
            component: () =>
              import(
                /* webpackChunkName: "pos_edit_orders" */ '../modules/pos/orders/KnOrderDetail/KnOrderDetail.vue'
              ),
            props: true,
            meta: {
              actions: [Actions.view],
              resources: ['detalleorden'],
            },

            beforeEnter: checkProfilePermissions,
          },
          {
            path: 'ordenes/solicitar-cancelacion/:id',
            name: 'Solicitar Cancelacion',
            component: () =>
              import(
                /* webpackChunkName: "pos_cancellation_request" */ '../modules/pos/orders/KnCancellationOrder/KnCancellationOrder.vue'
              ),
            props: true,
            meta: {
              actions: [Actions.view, Actions.change],
              resources: ['cancelacionorden'],
            },
            beforeEnter: checkProfilePermissions,
          },
          {
            path: 'inventario',
            name: 'Inventario',
            component: () =>
              import(
                /* webpackChunkName: "pos_inventory" */ '../modules/pos/inventory/KnInventory/KnInventory.vue'
              ),
            meta: {
              actions: [Actions.view],
              resources: ['inventario'],
            },
            beforeEnter: checkProfilePermissions,
          },
          {
            path: 'inventario/nuevo',
            name: 'Nuevo Inventario',
            component: () =>
              import(
                /* webpackChunkName: "pos_new_inventory" */ '../modules/pos/inventory/KnFormInventory/KnFormInventory.vue'
              ),
            props: true,
            meta: {
              actions: [Actions.add],
              resources: ['inventario'],
            },

            beforeEnter: checkProfilePermissions,
          },
          {
            path: 'inventario/editar/:id',
            name: 'Editar Inventario',
            component: () =>
              import(
                /* webpackChunkName: "pos_edit_inventory" */ '../modules/pos/inventory/KnFormInventory/KnFormInventory.vue'
              ),
            props: true,
            meta: {
              actions: [Actions.view, Actions.change],
              resources: ['inventario'],
            },
            beforeEnter: checkProfilePermissions,
          },
        ],
        meta: {
          actions: [Actions.view],
          resources: ['inventario', 'orden', 'producto'],
        },
        beforeEnter: checkProfilePermissions,
      },
      {
        path: 'acceso-restringido',
        name: 'Acceso restringido',
        component: () =>
          import(
            /* webpackChunkName: "acceso_restringido" */ '../modules/shared/views/KnAccessDenied/KnAccessDenied.vue'
          ),
      },
    ],
  },
];

export default appRoutes;
