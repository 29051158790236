import {
  deleteItem,
  getItem,
  postItem,
  putItem,
} from '../../../api/api-methods';
import vuetify from '../../../plugins/vuetify';

export const emptyTheme = {
  id: 0,
  nombre_tema: '',
  color_principal: '#0047BA',
  color_secundario: '#A45C81',
  color_terciario: '#FFB600',
  cuarto_color: '#0047BA',
  institucion_educativa: null,
  estatus_sistema: true,
  is_activo: true,
};

export const fetchThemes = async ({
  institutionId = null,
  themeName = null,
  systemStatus = null,
  isActive = null,
  primaryColor = null,
  secondaryColor = null,
  tertiaryColor = null,
  quaternaryColor = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const themeNameFilter =
      themeName !== null ? `&nombre_tema=${themeName}` : '';
    const isActiveFilter = isActive !== null ? `&is_activo=${isActive}` : '';
    const primaryColorFilter =
      primaryColor !== null ? `&color_principal=${primaryColor}` : '';
    const secondaryColorFilter =
      secondaryColor !== null ? `&color_secundario=${secondaryColor}` : '';
    const tertiaryColorFilter =
      tertiaryColor !== null ? `&color_terciario=${tertiaryColor}` : '';
    const quaternaryColorFilter =
      quaternaryColor !== null ? `&cuarto_color=${quaternaryColor}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/tema?'.concat(
        institutionFilter,
        systemStatusFilter,
        themeNameFilter,
        isActiveFilter,
        primaryColorFilter,
        secondaryColorFilter,
        tertiaryColorFilter,
        quaternaryColorFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los temas. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener temas. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener temas. ' + error,
      count: 0,
    };
  }
};

export const postTheme = async (theme) => {
  try {
    const response = await postItem('/app-personas/tema', theme);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear el tema. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return { ok: false, message: 'Error al intentar crear tema. ' + error };
  }
};

export const putTheme = async (theme) => {
  try {
    const { id } = theme;
    const response = await putItem(`/app-personas/tema/${id}`, theme);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el tema. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar tema. ' + error,
    };
  }
};

export const delTheme = async (id) => {
  try {
    const response = await deleteItem('/app-personas/tema', id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar el tema. ' + response.e,
      };
    }
    return { ok: true, data: 'Exito al eliminar tema' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar tema. ' + error,
    };
  }
};

export const postSetJustOneActiveTheme = async ({ institutionId, themeId }) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/establecer-tema-predeterminado-institucion-educativa',
      {
        id_institucion_educativa: institutionId,
        id_tema: themeId,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo activar el tema. ' + response.e,
      };
    }
    return { ok: true, data: 'Exito al activar tema' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar establecer tema activo. ' + error,
    };
  }
};

/** Metodos para almacenar en localStorage */

export const setLocalTheme = (theme) => {
  localStorage.setItem('kpTheme', JSON.stringify(theme));
};

export const getLocalTheme = () => {
  const localStorageTheme = localStorage.getItem('kpTheme');
  if (localStorageTheme) {
    return JSON.parse(localStorageTheme);
  } else {
    return null;
  }
};

export const removeLocalTheme = () => {
  localStorage.removeItem('kpTheme');
};

/** Metodos para sobreescribir los colores de vuetify */
export const setVuetifyColors = ({
  color_principal,
  color_secundario,
  color_terciario,
}) => {
  vuetify.framework.theme.themes.light.primary = color_principal;
  vuetify.framework.theme.themes.light.purple = color_secundario;
  vuetify.framework.theme.themes.light.warning = color_terciario;
};
