const state = () => ({
  ticket: [],
  checkoutStatus: null,
  sessionOrder: {
    customerId: null, // Los clientes son los alumnos
    orderNumber: null
  }
})

const getters = {
  ticketItems: state => state.ticket,
  ticketSubTotalPrice: state => {
    return state.ticket.reduce((sum, item) => {
      const price = parseFloat(item.price);
      const quantity = parseInt(item.quantity);
      if (!isNaN(price) && !isNaN(quantity)) {
        return (price * quantity) + sum;
      } else {
        return sum;
      }
    }, 0);
  },
  ticketTotalItems: state => {
    return state.ticket.reduce((sum, item) => {
      const quantity = parseInt(item.quantity);
      if (!isNaN(quantity)) {
        return quantity + sum;
      } else {
        return sum;
      }
    }, 0);
  },
  ticketSubTotalTaxes: state => {
    return state.ticket.reduce((sum, item) => {
      const price = parseFloat(item.price);
      const quantity = parseInt(item.quantity);
      const tax = parseFloat(item.tax);
      if (!isNaN(price) && !isNaN(quantity) && !isNaN(tax)) {
        return ((price * tax) * quantity) + sum;
      } else {
        return sum;
      }
    }, 0);
  },
  ticketTotal: state => {
    // console.log(state.ticket);
    return state.ticket.reduce((sum, item) => {
      const { price = 0, quantity = 0, tax = 0 } = item;
      const parsedPrice = parseFloat(price);
      const parsedQuantity = parseInt(quantity);
      let parsedTax = parseFloat(tax);
      if (isNaN(parsedTax)) parsedTax = 0;
      const totalPrice = parsedPrice * parsedQuantity * (1 + parsedTax);
      return sum + totalPrice;
    }, 0)
  },

  ticketItemById: (state) => (id) => {
    return state.ticket.find(item => item.productId === id)
  },
  ticketItemByIdAndOption: (state) => ({ id, optionId }) => {
    // console.log('id a buscar: ', id, 'optionId a buscar:', optionId);
    return state.ticket.find(item => item.productId === id && item.optionId === optionId)
  },
  ticketLength: (state) => {
    return state.ticket.length
  },
  isSessionOrderEmpty: (state) => {
    return state.sessionOrder.customerId === null
  },
  customerId: (state) => state.sessionOrder.customerId,
  orderNumber: (state) => state.sessionOrder.orderNumber
}

const actions = {
  loadTicketFromStorage({ commit }) {
    const customerId = localStorage.getItem('customerId')
    const ticketItems = localStorage.getItem('ticket')
    const orderNumber = localStorage.getItem('orderNumber')

    if (customerId) {
      commit('setSessionOrderCustomer', JSON.parse(customerId))
      commit('setSessionOrderNumber', JSON.parse(orderNumber))
      if (ticketItems) {
        commit('setTicketItems', JSON.parse(ticketItems))
      }
    }
  },
  cancelSession({ commit }) {
    localStorage.removeItem('customerId')
    localStorage.removeItem('ticket')
    localStorage.removeItem('orderNumber')

    commit('setSessionOrderCustomer', null)
    commit('setSessionOrderNumber', null)
    commit('removeAllItems')
  },
  addProductToTicket({ commit, getters }, item) {
    const product = getters.ticketItemByIdAndOption({ id: item.productId, optionId: item.optionId })
    // console.log('producto encontrado?', product);
    if (product) {
      commit('incrementItemQuantity', { id: item.productId, optionId: item.optionId })
    } else {
      commit('pushProductToTicket', item)
    }
  },
  decrementItemQuantity({ commit, getters }, item) {
    const product = getters.ticketItemByIdAndOption({ id: item.id, optionId: item.optionId })
    if (product.quantity <= 1) {
      commit('removeItem', { id: item.id, optionId: item.optionId })
    } else {
      commit('decrementItemQuantity', { id: item.id, optionId: item.optionId })
    }
  }
}

const mutations = {
  pushProductToTicket: (state, item) => {
    state.ticket.push({
      ...item,
      quantity: 1
    })
  },

  incrementItemQuantity(state, { id, optionId }) {
    const product = state.ticket.find(item => item.productId === id && item.optionId === optionId)
    product.quantity++
  },

  decrementItemQuantity(state, { id, optionId }) {
    const product = state.ticket.find(item => item.productId === id && item.optionId === optionId)
    product.quantity--
  },

  removeItem(state, { id, optionId }) {
    state.ticket = state.ticket.filter(item => !(item.productId === id && item.optionId === optionId));
  },

  removeAllItems(state) {
    state.ticket = []
  },

  setTicketItems(state, items) {
    state.ticket = items
  },

  setCheckoutStatus(state, status) {
    state.checkoutStatus = status
  },

  setSessionOrder(state, order) {
    state.sessionOrder = order
  },

  setSessionOrderCustomer(state, id) {
    state.sessionOrder.customerId = id
  },

  setSessionOrderNumber(state, orderNumber) {
    state.sessionOrder.orderNumber = orderNumber
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}