import store from '../store';
import { can } from '../modules/shared/helpers/permissionsUtils';

// eslint-disable-next-line no-unused-vars
const isAuthenticated = (to, from, next) => {
  // console.log('Dentro de isAuthenticated');
  // console.log('isAuthenticated to', to);
  // console.log('isAuthenticated from', from);
  if (to.meta.requireAuth) {
    if (!store.getters.isLoggedIn) {
      store.dispatch('logout');
      next({ name: 'Login' });
    } else {
      next({ name: 'Acceso restringido' });
    }
  } else {
    next();
  }
};

// eslint-disable-next-line no-unused-vars
const checkProfilePermissions = (to, from, next) => {
  // console.log('Dentro de checkProfilePermissions');
  // console.log('checkProfilePermissions to', to);
  // console.log('checkProfilePermissions from', from);
  const actions = to.meta.actions ? to.meta.actions : [];
  const resources = to.meta.resources ? to.meta.resources : [];

  store.dispatch('clearSearch');

  if (
    resources.some((resource) => can({ actions: actions, resource: resource }))
  ) {
    next();
  } else {
    next({ name: 'Acceso restringido' });
  }
};

export { isAuthenticated, checkProfilePermissions };
