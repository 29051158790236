import {
  getLocalCurrentPermissionGroup,
  setLocalCurrentPermissionGroup,
  removeLocalCurrentPermissionGroup,
} from '../../modules/shared/helpers/permissionsUtils';

const state = () => ({
  currentPermissionsGroup: getLocalCurrentPermissionGroup(),
});
const mutations = {
  setCurrentPermissionsGroup: (state, payload) =>
    (state.currentPermissionsGroup = payload),
};
const actions = {
  setCurrentPermissionsGroup({ commit }, permissions = []) {
    if (permissions) {
      commit('setCurrentPermissionsGroup', permissions);
      setLocalCurrentPermissionGroup(permissions);
    } else {
      commit('setCurrentPermissionsGroup', []);
      removeLocalCurrentPermissionGroup();
    }
  },
};
const getters = {
  authPermissionGroup(state) {
    return state.currentPermissionsGroup && state.currentPermissionsGroup.length
      ? state.currentPermissionsGroup
      : [];
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
